<script lang="ts">
	import '../app.css';
	import DebugScreens from '../lib/components/dev/DebugScreens.svelte';
	import { images } from '../lib/images';
	import { theme } from '../lib/stores/theme';

	theme.init();
</script>

<svelte:head>
	<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
	<link rel="icon" type="image/png" sizes="32x32" href={images.favicon32.img.src} />
	<link rel="icon" type="image/png" sizes="16x16" href={images.favicon16.img.src} />
	<link rel="manifest" href="/site.webmanifest" />
</svelte:head>

<slot />

<DebugScreens />
